import React, { useEffect, useState } from "react";
import { SearchFilterContainer } from "~/search-filter-container";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { UltraFilter } from "~/ultra-filter";
import { ActiveFilter } from "~/ultra-filter/types";
import { useGetAllProductsLazyQuery } from "./GetAllProducts.hotchoc.generated";
import { List, ListItem, ListItemText } from "rmwc";
import _ from "lodash";
import Card from "@material/react-card";

export const route = "/products";

type ProductFilter = {
  isActive: boolean | null;
  searchText: string;
};

type FilterFormProps = {
  onFiltersChanged: React.Dispatch<ProductFilter>;
};

const FilterForm: React.FC<FilterFormProps> = ({ onFiltersChanged }) => {
  const onChanged = (_filters: ActiveFilter[], searchText: string) => {
    onFiltersChanged({ searchText, isActive: null });
  };

  return (
    <div className="filter-form">
      <UltraFilter
        providers={[]}
        dialogTitle="Products Search"
        label="Type to search products"
        onFiltersChanged={onChanged}
      />
    </div>
  );
};

export const ProductsPage: React.FC = () => {
  const [filters, setFilters] = useState<ProductFilter>({
    isActive: null,
    searchText: "",
  });

  const [runQuery, { loading, data }] = useGetAllProductsLazyQuery({
    context: { clientName: "graphql2" },
  });

  useEffect(() => {
    void runQuery();
  }, []);

  const allProducts = data?.products?.all || [];

  const filterRegex = new RegExp(filters.searchText, "i");

  const products = allProducts.filter((x) => x.name.match(filterRegex));

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterForm onFiltersChanged={setFilters} />
      </SearchFilterContainer>
      <SearchListContent loading={loading}>
        <Card>
          {products && (
            <List>
              {_.chain(products)
                .sortBy((x) => x.name)
                .map((item) => (
                  <ListItem key={item.name}>
                    <ListItemText>
                      {item.name}
                      {!item.isActive ? " - (inactive)" : ""}
                    </ListItemText>
                  </ListItem>
                ))
                .value()}
            </List>
          )}
        </Card>
      </SearchListContent>
    </SearchPageWrapper>
  );
};
