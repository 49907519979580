import Card from "@material/react-card";
import moment from "moment";
import React, { useState } from "react";
import Button from "~/button";
import PageTitleRow from "~/page-title-row";
import CardTopRow from "~/card-top-row";
import CancelInvoice from "./CancelInvoice";
import { ProjectChargeForm as ChargeForm } from "~/project-charge-form";
import EditInvoiceNotes from "~/edit-invoice-notes/EditInvoiceNotes";
import "./Invoice.scss";
import Spinner from "~/spinner";
import BillInvoice from "./BillInvoice";
import { InvoiceSideBar } from "./InvoiceSideBar";
import RecallInvoice from "./RecallInvoice";
import ClickSwallower from "~/click-swallower";
import TableCell from "~/table-cell";
import cn from "classnames";
import { EditChargeTaxable } from "~/visuals/organisms/EditChargeTaxable";
import { AllItemsTaxable } from "~/visuals/organisms/AllItemsTaxable/AllItemsTaxable";
import { Body2 } from "@material/react-typography";
import { useRawJwt } from "~/Auth/AuthenticationManager";
import { RebillInvoiceButton } from "./Rebilling/RebillInvoiceButton";
import { InvoiceRebillInfo } from "./InvoiceRebillInfo";
import { AddChargesDialog } from "./AddChargesDialog";
import { useDownloadFile } from "~/file-loading/useDownloadFile";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import { quantityFormatter } from "~/quantityFormatter";
import { TaxableWarning } from "./TaxableWarning";
import { useUser } from "~/Auth/useUser";
import {
  ChargeType,
  Invoice,
  InvoiceLineItem,
  ValidationResponse,
} from "~/gql/types";
import { useRemoveLineItemMutation } from "./removeItem.generated";
import { InvoiceBreadcrumbs } from "./InvoiceBreadcrumbs";
import { LinkClickHandler } from "~/link-click-handler";
import {
  customRollupInvoice,
  customer as customerRoute,
  project as projectRoute,
} from "~/routes";
import _ from "lodash";
import { useInvoice, useRefreshInvoice } from "./InvoiceContext";
import { AddToRollup } from "./AddToRollup";
import { InvoiceRemoveLineItem } from "~/invoice-remove-lineitem";
import { useEditInvoiceNotesMutation } from "./editInvoiceNotes.generated";
import { DownloadBundledPdf } from "./DownloadBundledPdf";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const rateFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 6,
  minimumFractionDigits: 2,
});
type InvoiceRowProps = {
  item: InvoiceLineItem;
  project: Project;
  refetch: React.DispatchWithoutAction;
  canEdit: boolean;
  showRebilledFrom: boolean;
  showRebilledOn: boolean;
};

type FormValues = {
  notes: string;
};

const getShowRebilledFrom = (invoice: Invoice) => {
  const lineItems = invoice.lineItems as InvoiceLineItem[];
  return lineItems.some((x) => !!x.rebill?.rebilledFromInvoice);
};

const getShowRebilledOn = (invoice: Invoice) => {
  const lineItems = invoice.lineItems as InvoiceLineItem[];
  return lineItems.some((x) => !!x.rebill?.rebilledOnInvoice);
};

type TaxableDisplayProps = {
  canEdit: boolean;
  item: InvoiceLineItem;
  refetch: React.DispatchWithoutAction;
};

export const TaxableDisplay: React.FC<TaxableDisplayProps> = ({
  canEdit,
  item,
  refetch,
}) => (
  <>
    {canEdit && (
      <TableCell className="edit-taxable-checkbox">
        <EditChargeTaxable
          {...{
            id: item.projectChargeId,
            currentTaxable: item.taxable,
            refetch,
          }}
        />
      </TableCell>
    )}
    {!canEdit && <TableCell text={item.taxable ? "Yes" : "No"} />}
  </>
);

const InvoiceRow: React.FC<InvoiceRowProps> = ({
  item,
  project,
  refetch,
  canEdit,
  showRebilledFrom,
  showRebilledOn,
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [doMutation, { loading }] = useRemoveLineItemMutation();
  const variables = {
    invoiceNumber: item.invoiceNumber,
    chargeId: item.projectChargeId,
  };

  const employee = item.projectCharge?.employee;
  const employeeName = employee
    ? `${employee.firstName} ${employee.lastName}`
    : "";

  const itemClass = cn("line-item-row", { unbilled: !item.billable });

  const itemTotal = item.billable
    ? // This janky nonsense helps prevent rounding errors since JS
      // only has a floating-point data type. For instance,
      // 1248.26 * .75 should equal 936.195, but it computes out to
      // 936.194999999999, which causes it to round down incorrectly.
      // Scaling to whole numbers helps prevent these kinds of issues.
      (item.rate * 100 * item.quantity * 100) / 100 / 100
    : 0;

  return (
    <>
      <div
        className={itemClass}
        onClick={() => (canEdit ? setEditOpen(!editOpen) : undefined)}
      >
        <TableCell text={item.label} />
        <TableCell text={employeeName} />
        <TableCell text={item.projectCharge?.crewCode} />
        <TableCell text={moment(item.date).format("MM/DD/YYYY")} />
        <TableCell text={`$${rateFormatter.format(item.rate)}`} />
        <TableCell text={`${quantityFormatter.format(item.quantity)}`} />
        <TableCell text={formatter.format(itemTotal)} />
        {showRebilledFrom && (
          <TableCell className="item-rebilled">
            {item.rebill!.rebilledFromInvoice && (
              <Body2>
                <a
                  className="item-rebilled-from-link"
                  href={`/invoice/${item.rebill!.rebilledFromInvoice}`}
                >
                  {item.rebill!.rebilledFromInvoice}
                </a>
              </Body2>
            )}
          </TableCell>
        )}
        {showRebilledOn && (
          <TableCell className="item-rebilled">
            {item.rebill!.rebilledOnInvoice && (
              <Body2>
                <a
                  className="item-rebilled-on-link"
                  href={`/invoice/${item.rebill!.rebilledOnInvoice}`}
                >
                  {item.rebill!.rebilledOnInvoice}
                </a>
              </Body2>
            )}
          </TableCell>
        )}

        <TaxableDisplay {...{ item, refetch, canEdit }} />

        <TableCell className="notes" text={item.notes ?? ""} />
        {canEdit && (
          <TableCell>
            <ClickSwallower>
              <InvoiceRemoveLineItem
                {...{
                  refetch,
                  className: "delete-line-item",
                  remove: () => doMutation({ variables }),
                }}
              />
            </ClickSwallower>
            <ClickSwallower>
              <ChargeForm
                {...{
                  project,
                  charge: item.projectCharge!,
                  open: editOpen,
                  setOpen: setEditOpen,
                  onSuccessFn: refetch,
                  invoiceNumber: item.invoiceNumber,
                  showProjectField: false,
                }}
              />
            </ClickSwallower>
          </TableCell>
        )}
      </div>
      <Spinner open={loading} />
    </>
  );
};

type TotalRowProps = {
  invoice: Invoice;
};

const TotalRow: React.FC<TotalRowProps> = ({ invoice }) => {
  const showRebilledOn = getShowRebilledOn(invoice);
  const showRebilledFrom = getShowRebilledFrom(invoice);

  return (
    <div className={"line-item-row"}>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell className="total" text={formatter.format(invoice.total)} />
      {invoice.canEdit && <TableCell />}
      {showRebilledFrom && <TableCell />}
      {showRebilledOn && <TableCell />}
      <TableCell />
      <TableCell />
    </div>
  );
};

export const TaxItemRow: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const taxItem = invoice.lineItems!.find(
    (x) => x!.projectCharge!.type === ChargeType.Tax
  );

  if (!taxItem) {
    return null;
  }

  const showRebilledOn = getShowRebilledOn(invoice);
  const showRebilledFrom = getShowRebilledFrom(invoice);

  return (
    <div className={"line-item-row tax-item"}>
      <TableCell text={taxItem.label} />
      <TableCell />
      <TableCell text={taxItem.projectCharge?.crewCode} />
      <TableCell text={moment(taxItem.date).format("MM/DD/YYYY")} />
      <TableCell />
      <TableCell />
      <TableCell text={`$${rateFormatter.format(taxItem.rate)}`} />
      {invoice.canEdit && <TableCell />}
      {showRebilledFrom && <TableCell />}
      {showRebilledOn && <TableCell />}
      <TableCell />
      <TableCell />
    </div>
  );
};

const InvoicePage: React.FC = () => {
  const [editInvoiceOpen, setEditInvoiceOpen] = useState<boolean>(false);
  const [addChargesOpen, setAddChargesOpen] = useState<boolean>(false);
  const [createChargeOpen, setCreateChargeOpen] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const token = useRawJwt();
  const download = useDownloadFile();
  const user = useUser();

  const invoice = useInvoice();
  const refetch = useRefreshInvoice();

  const [doMutation, { loading }] = useEditInvoiceNotesMutation();

  if (!invoice) {
    return <Spinner open={true} />;
  }

  const canEdit = invoice.canEdit;

  const fetchPdf = async (url) => {
    setPdfLoading(true);
    void window
      .fetch(url, {
        method: "get",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: token ? `Bearer ${token}` : "",
          impersonation: user.isImpersonating ? user.email : "",
        },
      })
      .then(async (res) => {
        setPdfLoading(false);
        if (res.status === 200) {
          const blob = await res.blob();
          const file = new Blob([blob], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          download({ linkHref: fileURL, filename: invoice?.invoiceNumber });
        } else {
          throw new Error("There was a problem downloading the file");
        }
      });
  };
  const editNotes = async (value: FormValues) => {
    const result = await doMutation({
      variables: { invoiceNumber: invoice.invoiceNumber, notes: value.notes },
    });
    return result?.data?.invoices?.editNotes as ValidationResponse;
  };
  const actions = (
    <div className="actions">
      <InvoiceRebillInfo {...{ invoice }} />
      <BillInvoice {...{ invoice, refetch }} />
      <RebillInvoiceButton />
      <RecallInvoice {...{ invoice, refetch }} />
      <CancelInvoice {...{ invoice, refetch }} />
      <Button onClick={() => fetchPdf(invoice?.pdfDownloadUrl)}>
        Download pdf
      </Button>
      <DownloadBundledPdf {...{ invoiceNumber: invoice.invoiceNumber }} />
      <AddToRollup />
    </div>
  );

  const project = invoice.project as Project;
  const projectNumber = invoice.project!.number;
  const locationState = invoice.locationState;
  const county = invoice.county;

  const lineItems = invoice.lineItems as InvoiceLineItem[];

  const showRebilledFrom = lineItems.some(
    (x) => !!x.rebill?.rebilledFromInvoice
  );
  const showRebilledOn = lineItems.some((x) => !!x.rebill?.rebilledOnInvoice);

  const taxRateText = `Tax Rate: ${rateFormatter.format(
    invoice.taxRate * 100
  )}%`;
  const locationText = `Location: ${county} County, ${locationState}`;
  const invoiceDate = invoice.invoiceDate
    ? ` - ${moment(invoice.invoiceDate).format("M/DD/YYYY")}`
    : "";

  const showRebilledItems = [showRebilledFrom, showRebilledOn];

  const className = cn("line-item-table", {
    "can-edit": canEdit,
    "rebilled-one": showRebilledItems.filter((x) => x).length === 1,
    "rebilled-two":
      showRebilledItems.filter((x) => x).length === 2 ||
      (canEdit && showRebilledItems.filter((x) => x).length === 1),
  });

  const ba = invoice.billingAdmin!;
  const billedBy = invoice.billedBy;

  const showBilledBy =
    billedBy && ba.userPrincipalName != billedBy?.userPrincipalName;

  const title = (
    <>
      {`Invoice ${invoice.invoiceNumber}${invoiceDate} - ${invoice.state}`}
      <span>{locationText}</span>
      <span>{taxRateText}</span>
      <span>{`BA: ${ba?.firstName} ${ba?.lastName}`}</span>
      {showBilledBy && (
        <span>{`Billed by: ${billedBy?.firstName} ${billedBy?.lastName}`}</span>
      )}
    </>
  );

  const filteredItems = lineItems.filter(
    (x) => x.projectCharge?.type != ChargeType.Tax
  );
  const sortedItems = _.orderBy(filteredItems, [
    (x) => x.date,
    (x) => x.projectCharge!.crewCode,
    (x) => x.projectCharge!.employee?.lastName,
    (x) => x.label,
  ]);

  const rollupInvoice = invoice.customRollupInvoice;

  return (
    <>
      <div className="invoice-page">
        <InvoiceBreadcrumbs {...{ invoice }} />
        <PageTitleRow title={title} actions={actions} />
        <div className="header-links">
          <LinkClickHandler
            path={customerRoute.toRoute(invoice.customerNumber!).path}
          >
            <Body2>{`Customer ${invoice.customerNumber}: ${invoice.customerName}`}</Body2>
          </LinkClickHandler>
          <LinkClickHandler path={projectRoute.toRoute(project.number).path}>
            <Body2>{`Project ${project.number}: ${invoice.projectName}`}</Body2>
          </LinkClickHandler>
          {rollupInvoice && (
            <LinkClickHandler
              path={
                customRollupInvoice.toRoute(rollupInvoice.invoiceNumber).path
              }
            >
              <Body2>{`Custom Rollup Invoice ${rollupInvoice.invoiceNumber} (${rollupInvoice.state})`}</Body2>
            </LinkClickHandler>
          )}
        </div>
        <Spinner open={pdfLoading} />
        <div className="invoice-content">
          <Card className="line-items">
            <CardTopRow>
              {canEdit && (
                <div className="charge-buttons">
                  <Button onClick={() => setCreateChargeOpen(true)}>
                    Create charge
                  </Button>
                  <Button onClick={() => setAddChargesOpen(true)}>
                    Add charges
                  </Button>
                </div>
              )}
              <TaxableWarning {...{ invoice }} />
            </CardTopRow>

            <div className={className}>
              <div className={"line-item-row header"}>
                <TableCell text="Label" />
                <TableCell text="Employee" />
                <TableCell text="Crew Code" />
                <TableCell text="Date" />
                <TableCell text="Rate" />
                <TableCell text="Qty" />
                <TableCell text="Total" />
                {showRebilledFrom && <TableCell text="Rebilled From" />}
                {showRebilledOn && <TableCell text="Rebilled On" />}
                {canEdit && (
                  <TableCell className="edit-taxable-checkbox">
                    <AllItemsTaxable
                      {...{
                        items: lineItems.map((x) => ({
                          id: x.projectChargeId,
                          taxable: x.taxable,
                          type: x.projectCharge!.type,
                        })),
                        refetch,
                      }}
                    />
                    <Body2>Taxable</Body2>
                  </TableCell>
                )}
                {!canEdit && (
                  <TableCell>
                    <Body2>Taxable</Body2>
                  </TableCell>
                )}
                <TableCell className="notes" text="Notes" />
                {canEdit && <TableCell text="Delete" />}
              </div>
              {sortedItems?.map((item, i) => (
                <InvoiceRow
                  key={i}
                  {...{
                    item,
                    project,
                    refetch,
                    canEdit,
                    showRebilledOn,
                    showRebilledFrom,
                  }}
                />
              ))}
              <TaxItemRow {...{ invoice }} />
              <TotalRow {...{ invoice }} />
            </div>

            <EditInvoiceNotes
              {...{
                invoice,
                setOpen: setEditInvoiceOpen,
                open: editInvoiceOpen,
                refetch,
                editNotes: editNotes,
                loading,
              }}
            />
          </Card>

          <InvoiceSideBar {...{ invoice, setEditInvoiceOpen }} />
        </div>
      </div>
      <AddChargesDialog
        {...{
          open: addChargesOpen,
          onClose: () => setAddChargesOpen(false),
          cancelLabel: "Close",
          projectNumber,
          invoiceNumber: invoice.invoiceNumber,
          refetch,
        }}
      />
      <ChargeForm
        {...{
          project,
          open: createChargeOpen,
          setOpen: setCreateChargeOpen,
          onSuccessFn: refetch,
          invoiceNumber: invoice.invoiceNumber,
          showProjectField: false,
          mutation: "createInvoiceCharge",
        }}
      />
    </>
  );
};

export default InvoicePage;
