import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateServiceDescriptionMutationVariables = Types.Exact<{
  industry: Types.Scalars['String'];
  projectType: Types.Scalars['String'];
  description: Types.Scalars['String'];
  legacyWorkCode: Types.Scalars['String'];
  previousDisplayName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateServiceDescriptionMutation = { __typename?: 'MutationModel', serviceDescriptions?: { __typename?: 'ServiceDescriptionMutationModel', update?: { __typename?: 'ValidationResponse', errors?: Array<{ __typename?: 'ValidationError', argumentName?: string | null, message: string } | null> | null } | null } | null };


export const UpdateServiceDescriptionDocument = gql`
    mutation UpdateServiceDescription($industry: String!, $projectType: String!, $description: String!, $legacyWorkCode: String!, $previousDisplayName: String) {
  serviceDescriptions {
    update(
      industry: $industry
      projectType: $projectType
      description: $description
      legacyWorkCode: $legacyWorkCode
      previousDisplayName: $previousDisplayName
    ) {
      errors {
        argumentName
        message
      }
    }
  }
}
    `;
export type UpdateServiceDescriptionMutationFn = Apollo.MutationFunction<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>;

/**
 * __useUpdateServiceDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateServiceDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceDescriptionMutation, { data, loading, error }] = useUpdateServiceDescriptionMutation({
 *   variables: {
 *      industry: // value for 'industry'
 *      projectType: // value for 'projectType'
 *      description: // value for 'description'
 *      legacyWorkCode: // value for 'legacyWorkCode'
 *      previousDisplayName: // value for 'previousDisplayName'
 *   },
 * });
 */
export function useUpdateServiceDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>(UpdateServiceDescriptionDocument, options);
      }
export type UpdateServiceDescriptionMutationHookResult = ReturnType<typeof useUpdateServiceDescriptionMutation>;
export type UpdateServiceDescriptionMutationResult = Apollo.MutationResult<UpdateServiceDescriptionMutation>;
export type UpdateServiceDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateServiceDescriptionMutation, UpdateServiceDescriptionMutationVariables>;