import React, { useState } from "react";
import List, { ListItem, ListItemText } from "~/list";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Dialog, DialogTitle } from "~/dialog";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { ManageRefDataPage } from "~/manage-ref-data";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { useUpdateServiceDescriptionMutation } from "./UpdateServiceDescription.generated";
import {
  useInvalidateServiceDescriptions,
  useServiceDescriptions,
} from "~/refdata2/serviceDescriptions";
import { ValidationResponse } from "~/gql/types";

type EditedItemState = {
  industry: string;
  projectType: string;
  description: string;
  legacyWorkCode: string;
  previousDisplayName: string | null;
  dialogClosing: boolean;
};

export const ServiceDescriptions: React.FC = () => {
  useBreadcrumbs([{ text: "Service Descriptions" }], []);
  const serviceDescriptions = useServiceDescriptions();
  const invalidate = useInvalidateServiceDescriptions();
  const [doMutation, { loading: mutationPending }] =
    useUpdateServiceDescriptionMutation();

  const addAlert = useSnackBar();

  const title = `Showing ${
    serviceDescriptions ? serviceDescriptions.length : "-"
  } Service Descriptions`;

  const [editedItem, setEditedItem] = useState<EditedItemState | null>(null);

  const onAddNewClick = () => {
    setEditedItem({
      industry: "",
      projectType: "",
      description: "",
      legacyWorkCode: "",
      previousDisplayName: null,
      dialogClosing: false,
    });
  };

  const onEditClick = (item) => {
    setEditedItem({
      industry: item.industry,
      projectType: item.projectType,
      description: item.description,
      legacyWorkCode: item.legacyWorkCode,
      previousDisplayName: item.displayName,
      dialogClosing: false,
    });
  };
  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: "Updated Service Description",
      isSuccess: true,
    });
    invalidate();
    setEditedItem(null);
  };

  const runMutation = async (values: EditedItemState) => {
    const result = await doMutation({
      variables: {
        industry: values.industry,
        projectType: values.projectType,
        description: values.description,
        legacyWorkCode: values.legacyWorkCode,
        previousDisplayName: values.previousDisplayName,
      },
    });

    return result?.data?.serviceDescriptions?.update as ValidationResponse;
  };

  return (
    <ManageRefDataPage
      {...{
        title,
        onAddNewClick,
        loading: !serviceDescriptions || mutationPending,
      }}
    >
      <List twoLine>
        {(serviceDescriptions || [])
          .sort((a, b) => (a?.displayName! > b?.displayName! ? 1 : -1))
          .map((item) => (
            <ListItem key={item?.displayName} onClick={() => onEditClick(item)}>
              <ListItemText
                primaryText={item?.displayName}
                secondaryText={item?.legacyWorkCode}
              />
            </ListItem>
          ))}
      </List>

      <Dialog
        open={!!editedItem && !editedItem.dialogClosing}
        onClose={() => setEditedItem(null)}
      >
        <DialogTitle>
          {editedItem?.previousDisplayName
            ? "Edit Service Description"
            : "Create Service Description"}
        </DialogTitle>
        <MutationForm
          initialValues={editedItem ?? ({} as EditedItemState)}
          onSuccess={onSuccess}
          runMutation={runMutation}
        >
          <TextFormField
            formField="industry"
            label="Industry"
            helperText="The industry that this kind of service pertains to"
            required
          />
          <TextFormField
            formField="projectType"
            label="Project Type"
            helperText="The project type"
            required
          />
          <TextFormField
            formField="description"
            label="Description"
            helperText="The service description"
            required
          />
          <TextFormField
            formField="legacyWorkCode"
            label="Legacy Work Code"
            helperText="The work code from the legacy Workflow system that corresponds to this type of job"
            required
          />

          <ErrorRenderer
            render={(error) => (
              <FormBottomRow
                buttonText="Save"
                onCancel={() =>
                  setEditedItem((x) => ({ ...x!, dialogClosing: true }))
                }
                errorMessage={error}
              />
            )}
          />
        </MutationForm>
      </Dialog>
    </ManageRefDataPage>
  );
};
