import React from "react";
import { Charge } from "./types";
import { TotalRow } from "./row/ProjectChargeRow";
import { RateUnit } from "~/gql/types";

type ProjectTotalRowProps = {
  charges: Charge[];
};

export const ProjectTotalRow: React.FC<ProjectTotalRowProps> = (props) => {
  const { charges } = props;

  const reduceTotal = (
    value: (charge: Charge) => number | undefined | null,
    filter?: (charge: Charge) => boolean
  ): number =>
    charges
      .filter(filter ?? ((_) => true))
      .reduce((a, b) => a + (value(b) ?? 0), 0);

  const projectTotalTimesheetHours = reduceTotal(
    (x) => x.timesheetQuantity,
    (x) => x.unit === RateUnit.Hourly
  );

  const projectTotalBillableHours = reduceTotal(
    (x) => x.quantity,
    (x) => x.unit === RateUnit.Hourly
  );

  const projectTotalTimesheetQty = reduceTotal((x) => x.timesheetQuantity);

  const projectTotalQty = reduceTotal((x) => x.quantity);

  const projectTotal = reduceTotal(
    (x) => x.total,
    (x) => x.billable
  );

  return (
    <TotalRow
      {...{
        totalTimesheetHours: projectTotalTimesheetHours,
        totalBillableHours: projectTotalBillableHours,
        totalTimesheetQty: projectTotalTimesheetQty,
        totalQty: projectTotalQty,
        total: projectTotal,
        label: `Totals for Project ${charges[0].projectId}`,
        projectTotal: true,
      }}
    />
  );
};
