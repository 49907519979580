import React from "react";
import "./ExpenseForm.scss";
import TextFormField from "~/text-form-field";
import { FormProps } from "./CommonTypes";
import { MobileAllowanceExpense } from "~/gql/types";
import { ExpenseFormWrapper } from "./ExpenseFormWrapper";
import { CrewCodePickerFormField } from "~/visuals/organisms/CrewCodePickerFormField";
import { StateCountyPicker } from "~/visuals/organisms/StateCountyPicker";

type MobileAllowanceFormProps = FormProps & {
  expenseEntry?: MobileAllowanceExpense;
};

const MobileAllowanceTypeForm: React.FC<MobileAllowanceFormProps> = (props) => {
  return (
    <ExpenseFormWrapper {...{ ...props, typeName: "Mobile Allowance Expense" }}>
      <StateCountyPicker
        label="Location"
        helperText="The state and county where the work was performed. (use up/down arrows and enter key to select suggestions)"
        formField="location"
        required
      />
      <CrewCodePickerFormField
        label="Crew Code"
        formField="crewCode"
        helperText="The crew code applicable to this work. (use up/down arrows and enter key to select suggestions)"
        required
      />
      <TextFormField label="Notes" helperText="" formField="notes" textarea />
    </ExpenseFormWrapper>
  );
};

export default MobileAllowanceTypeForm;
