import { ActiveFilter, FilterProvider, Suggestion } from "~/ultra-filter/types";
import React, { ReactNode } from "react";
import { FilterProviderProps } from "~/filterProviders/types";
import {
  yesNoOptions,
  YesNoPicker,
} from "~/filterProviders/YesNoPickerFormField";

type CreateYesNoProviderArgs = FilterProviderProps & {
  label: string;
  quickFiltering: string;
  type: string;
  suggestions: Suggestion[];
  render: () => ReactNode;
};

const createYesNoProvider = (
  props: CreateYesNoProviderArgs
): FilterProvider => {
  const {
    documentationText: origDocText,
    type,
    label,
    suggestions,
    render,
    quickFiltering,
  } = props;

  const documentationText = `${origDocText} Use "${quickFiltering}" for quick filtering.`;

  return {
    render,
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return Promise.resolve(suggestions);
      }
      return Promise.resolve(
        suggestions.filter(
          (s) =>
            s.text.toLowerCase().startsWith(text.toLowerCase()) ||
            s.value.toLowerCase().startsWith(text.toLowerCase())
        )
      );
    },
    shouldSuggest: (text: string) =>
      text.toLowerCase().startsWith(quickFiltering.toLowerCase()),
    toFilter: (value: string) => ({
      label: `${label}: ${suggestions.find((s) => s.value === value)?.text}`,
      type,
      value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramState = params.get(type) || null;

      return Promise.resolve(
        paramState
          ? {
              label: `${label}: ${paramState}`,
              value: paramState,
              type,
            }
          : null
      );
    },
    toParam: (filter: ActiveFilter) => filter.value ?? "",
  };
};

export function useChargeBillableProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createYesNoProvider({
    documentationText,
    type: "billable",
    label: "Billable",
    quickFiltering: "Bill",
    suggestions: yesNoOptions,
    render: () => (
      <YesNoPicker formField="billable" label="Billable" helperText="" />
    ),
  });
}

export function useShowFutureTimesheetsProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createYesNoProvider({
    documentationText,
    type: "showFuture",
    label: "Show Future",
    quickFiltering: "fut",
    suggestions: yesNoOptions,
    render: () => (
      <YesNoPicker formField="showFuture" label="Show Future" helperText="" />
    ),
  });
}

export function useCertifiedPayrollProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createYesNoProvider({
    documentationText,
    type: "certifiedPayroll",
    label: "Certified Payroll",
    quickFiltering: "cert",
    suggestions: yesNoOptions,
    render: () => (
      <YesNoPicker
        formField="certifiedPayroll"
        label="Certified Payroll"
        helperText=""
      />
    ),
  });
}

export function useShowArchivedProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText } = props;
  return createYesNoProvider({
    documentationText,
    type: "showArchived",
    label: "Show Archived",
    quickFiltering: "arc",
    suggestions: yesNoOptions,
    render: () => (
      <YesNoPicker
        formField="showArchived"
        label="Show Archived"
        helperText=""
      />
    ),
  });
}
