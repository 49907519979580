import createRefdataApi from "../createRefdataApi";
import { ApolloClient } from "@apollo/client";
import {
  ServiceDescriptionsQuery,
  ServiceDescriptionsDocument,
} from "./query.generated";
export type { ServiceDescription } from "~/gql/types";
import { ServiceDescription } from "~/gql/types";

async function serviceDescriptionFetcher(client: ApolloClient<any>) {
  const result = await client.query<ServiceDescriptionsQuery>({
    query: ServiceDescriptionsDocument,
  });

  return result.data?.serviceDescriptions?.all as ServiceDescription[];
}

const api = createRefdataApi(serviceDescriptionFetcher, "service description");

export const ServiceDescriptionRefdataContext = api.Provider;
export const useServiceDescriptionsAsync = api.useDataAsync;
export const useServiceDescriptions = api.useData;
export const useInvalidateServiceDescriptions = api.useInvalidate;

export function useServiceDescriptionFromParam(): (
  name: string
) => Promise<ServiceDescription | null> {
  const getServiceDescriptions = useServiceDescriptionsAsync();

  return async (name: string) => {
    const result = await getServiceDescriptions();
    const type = result?.find((x) => x.displayName === name);

    return type ?? null;
  };
}
