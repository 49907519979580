import React, { useMemo } from "react";
import TextFormField from "~/text-form-field";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import FormCard from "~/form-card";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import TitleDeleteOption from "~/title-delete-option";
import { viewTaskRateSheet, taskRateSheets } from "~/routes";
import "./TaskRateForm.scss";
import Spinner from "~/spinner";
import FormBottomRow from "~/form-bottom-row";
import EnumDropdownFormField from "../EnumDropdownFormField";
import { Decorator } from "final-form";
import createDecorator from "final-form-calculate";
import { TaskRateType } from "~/gql/types";

type TaskRateFormProps = {
  type: "Create" | "Edit";
  rateSheetName: string;
  errorMessage?: string;
  loading: boolean;
  onDelete?: () => void;
  usesEmployeeRate: boolean;
  rateType: string | null;
};

export type TaskRateFormData = {
  name: string | null;
  usesEmployeeRate: boolean;
  billableAmount: number | null;
  requiresDaily: boolean;
  rateType: TaskRateType | null;
  unit: string | null;
  crewSize: number | null;
  glAccount: string | null;
  certifiedPayroll: boolean;
};

const TaskRateForm: React.FC<TaskRateFormProps> = ({
  type,
  rateSheetName,
  errorMessage,
  loading,
  onDelete,
  usesEmployeeRate,
  rateType,
}) => {
  useBreadcrumbs(
    [
      { text: "Task Rate Sheets", to: taskRateSheets.path },
      {
        text: rateSheetName,
        to: viewTaskRateSheet.toRoute(rateSheetName).path,
      },
      {
        text: `${
          type === "Create" ? "Create Task Rate" : `Edit ${rateSheetName}`
        }`,
      },
    ],
    [rateSheetName, type]
  );

  return (
    <div className="task-rate-form">
      <TitleDeleteOption title={type + " Task Rate"} onDelete={onDelete} />
      <Spinner open={loading} />

      <FormCard>
        <TextFormField
          label="Name"
          helperText="Task rate name"
          formField="name"
          required
        />
        <BinaryPickerFormField
          label="Uses Employee Rate?"
          helperText=""
          formField="usesEmployeeRate"
        />
        {!usesEmployeeRate && (
          <CurrencyFormField
            label="Billable Amount"
            helperText="Amount per hour billed"
            formField="billableAmount"
            required
          />
        )}
        <TextFormField label="GL Account" helperText="" formField="glAccount" />
        <EnumDropdownFormField
          label="Task Type"
          formField="rateType"
          helperText="Whether or not this task is done in the field or in an office"
          enumType="TaskRateType"
          required
        />
        <EnumDropdownFormField
          label="Task Unit"
          formField="unit"
          helperText="Whether or not this task is billed hourly or daily"
          enumType="TaskRateUnit"
          required
          disabled={usesEmployeeRate}
        />
        {rateType === TaskRateType.Field && (
          <TextFormField
            label="Crew Size"
            helperText=""
            formField="crewSize"
            type="number"
            required
          />
        )}
        <BinaryPickerFormField
          label="Requires Daily?"
          helperText="Indicates that employees must fill out a daily for this task"
          formField="requiresDaily"
        />
        <BinaryPickerFormField
          label="Certified Payroll?"
          helperText=""
          formField="certifiedPayroll"
        />
        <FormBottomRow
          errorMessage={errorMessage || null}
          buttonText={type === "Create" ? "Create" : "Save"}
          route={viewTaskRateSheet.toRoute(rateSheetName)}
        />
      </FormCard>
    </div>
  );
};

export default TaskRateForm;

export function useTaskRateCalculator(): Decorator<
  TaskRateFormData,
  Record<string, unknown>
> {
  const updateBillableAmount = (value: any, allValues: any) => {
    const { billableAmount } = allValues as TaskRateFormData;
    return value ? null : billableAmount;
  };

  const updateTaskUnit = (value: any, allValues: any) => {
    const { unit } = allValues as TaskRateFormData;
    return value ? "Hourly" : unit;
  };

  const calculator = useMemo(
    () =>
      createDecorator(
        {
          field: "usesEmployeeRate",
          updates: {
            billableAmount: updateBillableAmount,
          },
        },
        {
          field: "usesEmployeeRate",
          updates: {
            unit: updateTaskUnit,
          },
        }
      ) as Decorator<TaskRateFormData, Record<string, unknown>>,
    []
  );

  return calculator;
}
