import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTimesheetContext } from "./TimesheetContext";
import {
  TimesheetAction,
  TimesheetState,
  WorkDaySummary,
  Timesheet,
} from "./types";
import { Body2 } from "@material/react-typography";
import { TextHighlighter } from "~/text-highlighter";
import { addDurations, formatter } from ".";
import TableCell from "~/table-cell";
import { timesheetStatuses } from "./timesheetStatusConverter";
import { DayRow } from "./DayRow";
import { TimesheetActions } from "./TimesheetActions";
import { Employee, PayPeriod } from "~/gql/types";
import cn from "classnames";
import { Duration } from "moment";
import _ from "lodash";
import { TableRow } from "~/table-row";

type TimesheetRowProps = {
  row: Timesheet;
  search: string | null;
  state: TimesheetState;
  dispatch: TimesheetAction;
  columnCount: number;
};

export const TimesheetRow: React.FC<TimesheetRowProps> = ({
  row,
  search,
  state,
  dispatch,
  columnCount,
}) => {
  const searchText = search ? new RegExp(_.escapeRegExp(search)) : null;
  const { mode } = useTimesheetContext();

  const [timesheet, setTimesheet] = useState<Timesheet>(row);

  useEffect(() => {
    setTimesheet(row);
  }, [row]);

  const showName = mode === "AllTimesheets";
  const showSelect = mode !== "MyTimesheets";

  const daySummaries = (timesheet?.daySummaries ?? []) as WorkDaySummary[];
  const Highlight = ({ text }) => (
    <Body2>
      <TextHighlighter {...{ text, search: searchText }} />
    </Body2>
  );
  const reduceInitialVal = moment.duration("00:00");

  const getDuration = (item: (summary: WorkDaySummary) => string) =>
    daySummaries
      .map((x) => moment.duration(item(x)))
      .reduce((t1, t2) => addDurations(t1, t2), reduceInitialVal);

  const ptHours = getDuration((x) => x!.projectTime);
  const workHours = getDuration((x) => x!.time);
  const timeOffHours = getDuration((x) => x!.timeOff);

  const totalHours = addDurations(workHours, timeOffHours);

  const onSelectChange = (checked: boolean) => {
    if (checked) {
      dispatch({ tag: "SelectItem", item: timesheet });
    } else {
      dispatch({ tag: "UnselectItem", id: timesheet.timesheetId });
    }
  };

  const payPeriodMap = {
    [PayPeriod.Biweekly]: "Biwk",
    [PayPeriod.Weekly]: "Wk",
  };

  const payPeriod = (
    <TableCell
      text={payPeriodMap[timesheet.employee!.payPeriod]}
      title={timesheet.employee!.payPeriod}
    />
  );

  const rowClass = cn("timesheet-row week");

  const formatDuration = (x: Duration) => x.asHours().toFixed(2);

  const expandable =
    daySummaries && daySummaries.length > 0
      ? daySummaries
          .map((x) => (
            <DayRow
              day={x as WorkDaySummary}
              employee={timesheet.employee! as Employee}
              weekStatus={timesheet.status}
              key={`${timesheet.timesheetId}-${x.date}`}
            />
          ))
          .concat(
            <TableRow key={`${Math.random()}`}>
              <TableCell span={columnCount}>
                <TimesheetActions
                  {...{ row: timesheet, state, dispatch, setTimesheet }}
                />
              </TableCell>
            </TableRow>
          )
      : [];

  const selectProps = showSelect
    ? {
        onSelectChange,
        selected: state.selectedItems.some(
          (x) => x.timesheetId === timesheet.timesheetId
        ),
      }
    : {};

  return (
    <>
      <TableRow
        {...{
          expandable,
          className: rowClass,
          ...selectProps,
        }}
      >
        <TableCell
          text={moment(timesheet.weekBeginning).format("MM/DD/YYYY")}
        />
        {showName && (
          <TableCell>
            <Highlight text={`${timesheet.employee!.lastName}`} />
          </TableCell>
        )}
        {showName && (
          <TableCell>
            <Highlight text={`${timesheet.employee!.firstName}`} />
          </TableCell>
        )}
        {payPeriod}
        <TableCell text={timesheet.employee!.workLocation} />
        <TableCell text={formatDuration(ptHours)} />
        <TableCell text={formatDuration(workHours)} />
        <TableCell text={formatDuration(timeOffHours)} />
        <TableCell text={formatDuration(totalHours)} />
        <TableCell text={timesheet.totalMileage.toFixed(1)} />
        <TableCell text={formatter.format(timesheet.expensesReimbursable)} />
        <TableCell text={formatter.format(timesheet.payroll)} />
        <TableCell text={formatter.format(timesheet.companyPaid)} />
        <TableCell text={formatter.format(timesheet.totalExpensesPayable)} />
        <TableCell text={timesheetStatuses[timesheet.status]} />
      </TableRow>
    </>
  );
};
