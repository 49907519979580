import React, { useState } from "react";
import List, { ListItem, ListItemText } from "~/list";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Dialog, DialogTitle } from "~/dialog";
import TextFormField from "~/text-form-field";
import FormBottomRow from "~/form-bottom-row";
import { useSnackBar } from "~/snackbar";
import { ManageRefDataPage } from "~/manage-ref-data";
import { CorpLocation } from "~/refdata2/corpLocations";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { useUpdateCorpLocationMutation } from "./UpdateCorpLocation.generated";
import { useGetAllCorpLocationsQuery } from "./GetAllCorpLocations.hotchoc.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { ValidationResponse } from "~/gql/types";

type EditedItemState = {
  corpLocationId: string;
  name: string;
  companyName: string;
  isActive: boolean;
  dialogClosing: boolean;
};

export const CorpLocations: React.FC = () => {
  useBreadcrumbs([{ text: "Corporate Locations" }], []);

  const addAlert = useSnackBar();

  const { data, loading, refetch } = useGetAllCorpLocationsQuery({
    context: { clientName: "graphql2" },
  });
  const [doMutation, { loading: mutationPending }] =
    useUpdateCorpLocationMutation();

  const corpLocations = (data?.corpLocations?.all || []) as CorpLocation[];

  const title = `Showing ${
    data ? corpLocations.length : "-"
  } Corporate Locations`;

  const [editedItem, setEditedItem] = useState<EditedItemState | null>(null);

  const onAddNewClick = () => {
    setEditedItem({
      corpLocationId: "",
      name: "",
      companyName: "",
      isActive: true,
      dialogClosing: false,
    });
  };

  const onEditClick = (item: CorpLocation) => {
    setEditedItem({
      corpLocationId: item.id,
      name: item.name,
      companyName: item.companyName,
      isActive: item.isActive,
      dialogClosing: false,
    });
  };

  const onSuccess = () => {
    addAlert({
      key: `${Math.random()}`,
      message: "Updated Corporate Location",
      isSuccess: true,
    });
    void refetch();
    setEditedItem(null);
  };

  const runMutation = async (values: EditedItemState) => {
    const result = await doMutation({
      variables: {
        corpLocationId: values.corpLocationId,
        name: values.name,
        companyName: values.companyName,
        isActive: values.isActive,
      },
    });

    return result?.data?.corpLocations?.update as ValidationResponse;
  };

  return (
    <ManageRefDataPage
      {...{ title, onAddNewClick, loading: loading || mutationPending }}
    >
      <List twoLine>
        {corpLocations
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((item) => (
            <ListItem key={item?.id} onClick={() => onEditClick(item)}>
              <ListItemText
                primaryText={`${item?.id}${
                  !item?.isActive ? " - (inactive)" : ""
                }`}
                secondaryText={item?.name}
              />
            </ListItem>
          ))}
      </List>

      <Dialog
        open={!!editedItem && !editedItem.dialogClosing}
        onClose={() => setEditedItem(null)}
      >
        <DialogTitle>
          {editedItem?.corpLocationId
            ? "Edit Corporate Location"
            : "Create Corporate Location"}
        </DialogTitle>
        <MutationForm
          initialValues={editedItem ?? ({} as EditedItemState)}
          runMutation={runMutation}
          onSuccess={onSuccess}
        >
          {!editedItem?.corpLocationId && (
            <TextFormField
              formField="corpLocationId"
              label="Id"
              helperText="The id for the location"
              required
            />
          )}
          <TextFormField
            formField="name"
            label="Name"
            helperText="The location name"
            required
          />
          <TextFormField
            formField="companyName"
            label="Company Name"
            helperText="The name of the company"
            required
          />
          <BinaryPickerFormField
            formField="isActive"
            label="Is Active"
            helperText=""
          />

          <ErrorRenderer
            render={(error) => (
              <FormBottomRow
                buttonText="Save"
                onCancel={() =>
                  setEditedItem((x) => ({ ...x!, dialogClosing: true }))
                }
                errorMessage={error}
              />
            )}
          />
        </MutationForm>
      </Dialog>
    </ManageRefDataPage>
  );
};
