import React, { useState } from "react";
import TableCell from "~/table-cell";
import { Dialog, DialogContent } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import Button from "~/button";
import { ImageInfo } from "~/file-loading/useLoadFile";
import {
  DownloadFileProps,
  useDownloadFile,
} from "~/file-loading/useDownloadFile";
import { InlineProgress } from "~/inline-progress";
import { useLazyLoadImage } from "~/visuals/pages/ExpensesSearch/useLazyLoadImage";
import { Employee } from "~/gql/types";

export type ReceiptTypeExpense = {
  __typename: "FuelExpense" | "ReceiptValuedExpense" | "PerDiemExpense";
  employee: Employee;
  date: string;
  receiptImageUrl?: string;
  fuelReceiptUrl?: string;
  perDiemReceiptUrl?: string;
  receiptImageId?: string;
  fuelReceiptId?: string;
  perDiemReceiptId?: string;
};

export type ReceiptHandlerProps = {
  expense: ReceiptTypeExpense;
  mockDownloader?: (args: DownloadFileProps) => void;
};

export const ReceiptActions: React.FC<ReceiptHandlerProps> = ({
  expense,
  mockDownloader,
}) => {
  const download = useDownloadFile();
  const downloadImage = mockDownloader ?? download;

  const imageUrlMap = {
    FuelExpense: expense.fuelReceiptUrl!,
    ReceiptValuedExpense: expense.receiptImageUrl!,
    PerDiemExpense: expense.perDiemReceiptUrl!,
  };
  const getImage = useLazyLoadImage(imageUrlMap[expense.__typename]);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    image: ImageInfo | null;
  }>({ open: false, image: null });

  const filename = `${expense.employee.lastName}-${expense.employee.firstName}-${expense.date}-${expense.__typename}`;

  const handleDownload = async () => {
    const imageInfo = await getImage();
    downloadImage({ linkHref: imageInfo.url, filename });
  };

  const previewImage = async () => {
    setDialogState((st) => ({ ...st, open: true }));
    const imageInfo = await getImage();
    setDialogState((st) => ({ ...st, image: imageInfo }));
  };

  const isPdf = dialogState.image?.type === "application/pdf";
  const url = dialogState.image?.url;

  return (
    <ClickSwallower>
      <TableCell className="receipt-actions">
        <Button title="preview" icon="preview" onClick={previewImage} />
        <Button
          title="download"
          icon="file_download"
          onClick={handleDownload}
        />
      </TableCell>

      <Dialog
        open={dialogState.open}
        onClose={() => setDialogState((st) => ({ ...st, open: false }))}
        data-open={dialogState.open}
        className="receipt-image-modal"
        portalize
      >
        <DialogContent>
          {!dialogState.image && (
            <div className="receipt-loading">
              <InlineProgress />
            </div>
          )}
          <>
            {!isPdf && url && (
              <img src={url} className="receipt-image" alt={""} />
            )}
            {isPdf && url && <embed src={url} className="receipt-image" />}
          </>
          <div className="receipt-close-button">
            <Button
              onClick={() => setDialogState((st) => ({ ...st, open: false }))}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </ClickSwallower>
  );
};
