import React, { useContext } from "react";
import { Form } from "react-final-form";
import { FormApi, FORM_ERROR, Decorator } from "final-form";
import { ValueContext } from "./ValueContext";
import _ from "lodash";
import { FormEnterHandler } from "./FormEnterHandler";
import { ValidationError, ValidationResponse } from "~/gql/types";

type OnSuccessProps<TResponse, TFormData> = {
  response: TResponse;
  values: TFormData;
  form: FormApi;
};

type MutationFormProps<TFormData, TResponse extends ValidationResponse> = {
  initialValues: TFormData;
  runMutation: (values: TFormData) => Promise<ValidationResponse>;
  children: React.ReactNode;
  onSuccess: React.Dispatch<OnSuccessProps<TResponse, TFormData>>;
  setLoading?: React.Dispatch<boolean>;
  allowPristineSubmit?: boolean;
  decorators?: Decorator<any, Record<string, any>>[];
  remountFormOn?: any;
};

type MutationFormFunc = <TFormData, TResponse extends ValidationResponse>(
  props: MutationFormProps<TFormData, TResponse>
) => React.ReactElement;

const transformValidationResponse = (
  fields: string[],
  errors?: ValidationError[]
): Record<string, any> | void => {
  if (!errors) {
    return { [FORM_ERROR]: UNEXPECTED_ERROR };
  }
  if (errors.length > 0) {
    const [argErrors, noArgErrors] = _.partition(
      errors,
      (x) => !!x.argumentName && fields.includes(x.argumentName)
    );

    const formErrorMessage =
      noArgErrors.map((x) => x.message).join("; ") || undefined;

    const fieldErrors = _.chain(argErrors)
      .map((x) => [x.argumentName, x.message])
      .fromPairs()
      .value();

    return {
      ...fieldErrors,
      [FORM_ERROR]: formErrorMessage,
    };
  }
};

export const UNEXPECTED_ERROR = "Unexpected error";

export const ErrorContext = React.createContext("");

export const MutationForm: MutationFormFunc = <
  TFormData,
  TResponse extends ValidationResponse
>(
  props: MutationFormProps<TFormData, TResponse>
) => {
  const {
    children,
    initialValues,
    runMutation,
    onSuccess,
    setLoading = () => undefined,
    allowPristineSubmit,
    decorators,
    remountFormOn,
  } = props;

  const fieldKeys = Object.keys(initialValues as Record<string, any>);

  const internalOnSubmit = async (values: any, form: FormApi) => {
    const state = form.getState();
    if (state.pristine && !allowPristineSubmit) {
      return;
    }
    setLoading(true);
    let errors, validationResponse: ValidationResponse;
    try {
      validationResponse = await runMutation(values as TFormData);
      const validationErrors = validationResponse?.errors;
      errors = transformValidationResponse(
        fieldKeys,
        validationErrors as ValidationError[]
      );
    } catch (x) {
      // this console.log is intentional so we can see
      // unexpected errors in tests
      console.log(x);
      errors = transformValidationResponse(fieldKeys, undefined);
    }
    setLoading(false);

    if (errors) {
      return errors;
    }

    form.reset();
    onSuccess({ response: validationResponse! as TResponse, values, form });
  };

  return (
    <Form
      onSubmit={internalOnSubmit}
      initialValues={initialValues as any}
      decorators={decorators}
      key={remountFormOn}
      render={({ handleSubmit, error, submitError, values }) => (
        <form onSubmit={handleSubmit}>
          <ErrorContext.Provider value={submitError || error || ""}>
            <ValueContext.Provider value={values}>
              {children}
            </ValueContext.Provider>
          </ErrorContext.Provider>
          <FormEnterHandler />
        </form>
      )}
    />
  );
};

type ErrorRendererProps = {
  render: (error: string) => React.ReactElement;
};

export const ErrorRenderer: React.FC<ErrorRendererProps> = ({ render }) => {
  const error = useContext(ErrorContext);

  return render(error);
};
