import React, { useState } from "react";
import moment from "moment";
import { SearchFilterContainer } from "~/search-filter-container";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { Table } from "~/table";
import TableCell from "~/table-cell";
import { TableRow } from "~/table-row";
import "./revenue-reporting.scss";
import {
  RevenueReportFilteringArgsInput,
  RevenueReportGroupingArgsInput,
} from "~/gql/types";
import { GroupingControls } from "./GroupingControls";
import { FilteringControls } from "./FilteringControls";
import { useGroupingOptionsFromParams } from "./useGroupingOptionsFromParams";
import {
  GetRevenueReportQuery,
  useGetRevenueReportQuery,
} from "./getRevenueReport.generated";
import { ReportRow } from "./ReportRow";
import { addPercentage, headerLabels } from "./dataUtils";
import { DownloadCsvButton } from "./DownloadCsvButton";

export const RevenueReporting: React.FC = () => {
  const [groupingOptions, setGroupingOptions] = useGroupingOptionsFromParams();
  const [filters, setFilters] = useState<RevenueReportFilteringArgsInput>({
    after: null,
    before: null,
    serviceDescriptionId: null,
    customerNumber: null,
    industry: null,
    officeCode: null,
    projectNumber: null,
    projectManagerEmail: null,
    projectType: null,
  });

  const { data, loading } = useGetRevenueReportQuery({
    variables: {
      filtering: {
        ...filters,
        after: filters.after && moment(filters.after).format("YYYY-MM-DD"),
        before: filters.before && moment(filters.before).format("YYYY-MM-DD"),
      },
      grouping: groupingOptions,
    },
  });

  return (
    <SearchPageWrapper className="revenue-reporting">
      <SearchFilterContainer>
        <FilteringControls setFilters={setFilters} />
        <GroupingControls
          options={groupingOptions}
          setOptions={setGroupingOptions}
        />
      </SearchFilterContainer>

      <div>{data && <DownloadCsvButton data={data} />}</div>
      <SearchListContent loading={loading}>
        {data && <ResultsDisplay {...{ data, groupingOptions }} />}
      </SearchListContent>
    </SearchPageWrapper>
  );
};

type ResultsDisplayProps = {
  data: GetRevenueReportQuery;
  groupingOptions: RevenueReportGroupingArgsInput;
};

const ResultsDisplay: React.FC<ResultsDisplayProps> = ({
  data,
  groupingOptions,
}) => {
  const headerKeys = Object.keys(headerLabels).filter(
    (x) => groupingOptions[x]
  );

  const groupingCount = headerKeys.length;

  const transformedRows = addPercentage(data.invoices?.revenueReport as any);

  return (
    <>
      <Table
        columnGap="16px"
        columnCount={groupingCount + 2}
        columnWidths={`repeat(${groupingCount}, 1fr) max-content max-content`}
      >
        <TableRow header>
          {headerKeys.map((key) => (
            <TableCell text={headerLabels[key]} key={key} />
          ))}
          <TableCell text="Total" align="right" />
          <TableCell text="%" align="right" />
        </TableRow>
        {transformedRows.map((row, idx) => (
          <ReportRow row={row!} key={idx} />
        ))}
      </Table>
    </>
  );
};
