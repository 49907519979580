import React from "react";
import { WorkDaySummary } from "./types";
import moment from "moment";
import { useTimesheetContext } from "./TimesheetContext";
import WorkWeekRow from "./WorkWeekRow";
import TableCell from "~/table-cell";
import { addDurations, formatter } from ".";
import { Employee, TimesheetStatus } from "~/gql/types";
import { Duration } from "moment";

type DayRowProps = {
  day: WorkDaySummary;
  employee: Employee;
  weekStatus: TimesheetStatus;
};

export const DayRow: React.FC<DayRowProps> = ({
  day,
  employee,
  weekStatus,
}) => {
  const projectTime = moment.duration(day.projectTime);
  const time = moment.duration(day.time);
  const timeOff = moment.duration(day.timeOff);
  const date = moment(day.date).format("YYYY-MM-DD");
  const { mode } = useTimesheetContext();

  const showName = mode === "AllTimesheets";

  const formatDuration = (x: Duration) => x.asHours().toFixed(2);

  return (
    <WorkWeekRow
      className="timesheet-row day"
      review={mode !== "MyTimesheets"}
      date={date}
      userPrincipalName={employee.userPrincipalName}
      weekStatus={weekStatus}
    >
      {mode !== "MyTimesheets" && <TableCell />}
      <TableCell text={moment(day.date).format("MM/DD/YYYY")} />
      <TableCell text={moment(day.date).format("ddd")} />
      {showName && <TableCell />}
      {showName && <TableCell />}
      <TableCell />
      <TableCell text={projectTime.asHours().toFixed(2)} />
      <TableCell text={formatDuration(time)} />
      <TableCell text={formatDuration(timeOff)} />
      <TableCell text={formatDuration(addDurations(time, timeOff))} />
      <TableCell text={day.mileage.toFixed(1)} />
      <TableCell text={formatter.format(day.expensesReimbursable)} />
      <TableCell text={formatter.format(day.payroll)} />
      <TableCell text={formatter.format(day.companyPaid)} />
      <TableCell text={formatter.format(day.totalExpensesPayable)} />
      <TableCell />
    </WorkWeekRow>
  );
};
