import React, { useState } from "react";
import { Invoice, Project } from "~/gql/types";
import TableCell from "~/table-cell";
import moment from "moment";
import ClickSwallower from "~/click-swallower";
import { currencyFormatter } from "~/currencyFormatter";
import { AddInvoice } from "./AddRollupInvoice";
import { LinkClickHandler } from "~/link-click-handler";
import { invoice as invoiceRoute } from "~/routes";
import { RemoveInvoice } from "./RemoveRollupInvoice";
import styles from "./RollupInvoiceTable.module.scss";
import cn from "classnames";

type InvoiceRowProps = {
  customRollupInvoiceNumber: string;
  canManageCri: boolean;
  invoice: Invoice | null;
  project: Project;
  refetch: React.DispatchWithoutAction;
};

export const InvoiceRow: React.FC<InvoiceRowProps> = (props) => {
  const { invoice } = props;

  if (invoice) {
    return (
      <LinkClickHandler path={invoiceRoute.toRoute(invoice.invoiceNumber).path}>
        <InvoiceRowComponent {...props} />
      </LinkClickHandler>
    );
  }

  return <InvoiceRowComponent {...props} />;
};

const InvoiceRowComponent: React.FC<InvoiceRowProps> = ({
  customRollupInvoiceNumber,
  invoice,
  project,
  refetch,
  canManageCri,
}) => {
  const [open, setOpen] = useState(false);

  if (!invoice) {
    return (
      <div className={cn(styles["line-item-row"], styles["empty"])}>
        <TableCell text={`${project.number}`} />
        <TableCell text={project.name} />
        <TableCell text="(No invoice selected)" />
        <TableCell text="" />
        <TableCell text="" />
        <TableCell className={styles["action-button"]} span={2}>
          {canManageCri && (
            <AddInvoice
              {...{
                open,
                setOpen,
                projectNumber: project.number,
                customRollupInvoiceNumber,
                refetch,
              }}
            />
          )}
        </TableCell>
      </div>
    );
  }
  const invoiceNumber = invoice
    ? invoice.invoiceNumber
    : "(No invoice selected)";
  const isEmpty = !invoice;
  const ActionButton = invoice ? (
    <RemoveInvoice {...{ customRollupInvoiceNumber, invoiceNumber, refetch }} />
  ) : (
    <AddInvoice
      {...{
        open,
        setOpen,
        projectNumber: project.number,
        customRollupInvoiceNumber,
        refetch,
      }}
    />
  );

  const invoiceDate = invoice
    ? moment(invoice?.invoiceDate).format("MM/DD/YYYY")
    : "";
  const invoiceTotal = invoice ? currencyFormatter.format(invoice.total) : "";

  const rowClasses = cn(styles["line-item-row"], isEmpty ? "empty" : "");

  return (
    <div className={rowClasses}>
      <TableCell text={`${project.number}`} />
      <TableCell text={project.name} />
      <TableCell text={invoiceNumber} />
      <TableCell text={invoiceDate} />
      <TableCell text={invoice?.state ?? ""} />
      <TableCell text={invoiceTotal} className={styles["total-column"]} />
      <TableCell className={styles["action-button"]}>
        {canManageCri && <ClickSwallower>{ActionButton}</ClickSwallower>}
      </TableCell>
    </div>
  );
};
