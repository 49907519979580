import React from "react";
import TableCell from "~/table-cell";
import { TableRow } from "~/table-row";
import "./revenue-reporting.scss";
import { currencyFormatter } from "~/currencyFormatter";
import { ReportRowData, toLabelData } from "./dataUtils";

type ReportRowProps = {
  row: ReportRowData;
};

export const ReportRow: React.FC<ReportRowProps> = ({ row }) => {
  const labelData = toLabelData(row).filter((x) => !!x);

  const percentage = `${(row.percentage * 100).toFixed(2)}%`;

  return (
    <TableRow className="result-row">
      {labelData.map((x, idx) => (
        <TableCell text={x!} key={idx} />
      ))}
      <TableCell text={currencyFormatter.format(row?.total)} align="right" />
      <TableCell text={percentage} align="right" />
    </TableRow>
  );
};
