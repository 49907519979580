import React from "react";
import { Navigate } from "react-router-dom";
import { viewTaskRateSheet } from "~/routes";
import TaskRateForm, {
  TaskRateFormData,
  useTaskRateCalculator,
} from "~/visuals/organisms/TaskRateForm";
import { FORM_ERROR } from "final-form";
import { Form } from "react-final-form";
import { useCreateTaskRateMutation } from "./query.generated";
import { TaskRateType, TaskRateUnit } from "~/gql/types";

type NewTaskRateProps = {
  rateSheetName: string;
  useReload(): React.DispatchWithoutAction;
};

const NewTaskRate: React.FC<NewTaskRateProps> = ({
  rateSheetName,
  useReload,
}) => {
  const [doMutation, { data, loading }] = useCreateTaskRateMutation();

  const reloadRateSheet = useReload();
  const calculator = useTaskRateCalculator();

  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const createTaskRate = async (values: TaskRateFormData) => {
    const rateType = values?.rateType as TaskRateType;

    const { data } = await doMutation({
      variables: {
        rateName: values?.name,
        rateSheetName: rateSheetName,
        billableAmount: parseNum(values?.billableAmount),
        requiresDaily: values?.requiresDaily,
        rateType: rateType,
        unit: values?.unit as TaskRateUnit,
        usesEmployeeRate: values.usesEmployeeRate,
        crewSize: rateType === TaskRateType.Field ? +values.crewSize : 1,
        glAccount: values?.glAccount || null,
        certifiedPayroll: values.certifiedPayroll,
      },
    });

    reloadRateSheet();

    const responseErrors = data?.taskRates?.addRate?.errors || [];
    if (responseErrors.length > 0) {
      const errors = {};
      const messages: string[] = [];

      responseErrors
        .map((x) => x!)
        .forEach(({ argumentName, message }) => {
          if (argumentName) {
            errors[argumentName] = message;
          } else if (message) {
            messages.push(message);
            errors[FORM_ERROR] = messages.join(" ");
          }
        });
      return errors;
    }
  };

  if (
    data &&
    !loading &&
    (data?.taskRates?.addRate?.errors?.length ?? 0) === 0
  ) {
    return <Navigate to={viewTaskRateSheet.toRoute(rateSheetName).path} />;
  }

  const initialValues = {
    name: null,
    billableAmount: null,
    requiresDaily: false,
    rateType: null,
    unit: null,
    usesEmployeeRate: false,
    glAccount: null,
    certifiedPayroll: false,
  };

  return (
    <Form
      onSubmit={createTaskRate}
      initialValues={initialValues}
      decorators={[calculator]}
      render={({ handleSubmit, submitError, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TaskRateForm
              errorMessage={submitError}
              rateSheetName={rateSheetName}
              loading={loading}
              type="Create"
              usesEmployeeRate={values.usesEmployeeRate}
              rateType={values.rateType}
            />
          </form>
        );
      }}
    />
  );
};

export default NewTaskRate;
