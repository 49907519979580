import React, { useState } from "react";
import "./TableRow.scss";
import { useTableContext } from "~/table";
import cn from "classnames";
import { LinkClickHandler } from "~/link-click-handler";
import { Chevron } from "~/chevron";
import TableCell from "~/table-cell";
import ClickSwallower from "~/click-swallower";
import { CheckBox, CheckBoxProps } from "~/checkbox";
import { Ripple } from "rmwc";

type TableRowProps = {
  minHeight?: number;
  header?: boolean;
  className?: string;
  expandable?: React.ReactElement[];
  link?: string;
  expandableMargin?: number;
  selected?: boolean;
  onSelectChange?: (checked: boolean) => void;
  onClick?: () => void;
};

export const DEFAULT_CLOSED_MARGIN = -500;

const Expandable: React.FC<{
  expandable: React.ReactElement[];
  expanded: boolean;
  columnCount: number;
  expandableMargin?: number;
}> = ({ expandable, expanded, columnCount, expandableMargin }) => {
  const style = { gridColumn: `1 / ${columnCount + 1}` };

  return (
    <div className="wolfkrow-table-expandable-container" style={style}>
      <div
        className={cn("wolfkrow-table-expandable", { expanded })}
        style={{
          ...style,
          marginTop: expanded ? 0 : expandableMargin ?? DEFAULT_CLOSED_MARGIN,
        }}
      >
        {expandable.map((e) => e)}
        <div />
      </div>
    </div>
  );
};

export const TableRow: React.FC<TableRowProps> = (props) => {
  const {
    minHeight,
    header,
    expandable,
    children,
    className,
    link,
    expandableMargin,
    onSelectChange,
    selected,
    onClick,
  } = props;

  const {
    columnCount,
    rowMinHeight: tableRowMinHeight,
    selectable,
    onSelectAll,
    allSelected,
  } = useTableContext();

  const [expanded, setExpanded] = useState(false);

  const rowStyle = cn("wolfkrow-table-row", className, {
    header,
    expanded,
  });

  const minimumHeight = minHeight ?? tableRowMinHeight ?? 48;

  const wrapperProps = {
    className: rowStyle,
    style: {
      minHeight: `${minimumHeight}px`,
      gridColumn: `1 / ${columnCount + 1}`,
    },
    onClick: () => {
      onClick && onClick();
      if (expandable) {
        setExpanded(!expanded);
      }
    },
  };

  const childComponents = (
    <>
      {onSelectChange && !header && (
        <RowCheckbox
          onChange={onSelectChange}
          checked={selected ?? false}
          className="wolfkrow-row-select"
        />
      )}
      {onSelectAll && header && (
        <RowCheckbox
          onChange={onSelectAll}
          checked={allSelected ?? false}
          className="wolfkrow-table-select"
        />
      )}
      {children}
      {expandable && (
        <TableCell>
          <Chevron {...{ expanded }} />
        </TableCell>
      )}
    </>
  );

  if (!selectable && (onSelectChange || selected)) {
    throw new Error(
      "Cannot use onSelectChange or selected when the table is not selectable"
    );
  }

  return (
    <>
      {link ? (
        <LinkClickHandler {...wrapperProps} path={link}>
          {childComponents}
        </LinkClickHandler>
      ) : onClick ? (
        <Ripple>
          <div {...wrapperProps}>{childComponents}</div>
        </Ripple>
      ) : (
        <div {...wrapperProps}>{childComponents}</div>
      )}
      {expandable && (
        <Expandable
          {...{
            columnCount,
            expandable,
            expanded,
            expandableMargin,
          }}
        />
      )}
    </>
  );
};

const RowCheckbox: React.FC<CheckBoxProps & { className?: string }> = (
  props
) => (
  <TableCell className={props.className}>
    <ClickSwallower>
      <CheckBox {...props} />
    </ClickSwallower>
  </TableCell>
);
