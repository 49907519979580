export { DialogContent, DialogTitle, DialogButton } from "rmwc";

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Dialog as RmwcDialog } from "rmwc";
import type { DialogProps as RmwcDialogProps } from "rmwc";

export type DialogProps = RmwcDialogProps &
  React.HTMLProps<any> & {
    portalize?: boolean;
  };

let portalContainer: Element | null | "unset" = "unset";

export const Dialog: React.FC<DialogProps> = ({ portalize, ...otherProps }) => {
  const { open } = otherProps;
  const [shouldRender, setShouldRender] = useState(open);
  useEffect(() => {
    if (open) {
      setShouldRender(true);
    } else {
      setTimeout(() => setShouldRender(false), 500);
    }
  }, [open]);

  if (portalContainer === "unset") {
    portalContainer = document.querySelector("#portal-container");
  }

  if (portalContainer === null || !portalize) {
    return <RmwcDialog {...otherProps} />;
  }

  if (!shouldRender) {
    return null;
  }

  return ReactDOM.createPortal(<RmwcDialog {...otherProps} />, portalContainer);
};
