import React from "react";
import { useTimesheetContext } from "./TimesheetContext";
import TableCell from "~/table-cell";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { ApproveAll } from "./ApproveAll";
import Card from "@material/react-card";
import { TimesheetRow } from "./TimesheetRow";
import { VisibilityListener } from "~/visibility-listener";
import { SearchListContent } from "~/search-page-wrapper";
import { Table } from "~/table";
import { TableRow } from "~/table-row";
import { TimesheetReviewDisplayProps } from "./types";

export const TimesheetTable: React.FC<TimesheetReviewDisplayProps> = ({
  state,
  dispatch,
}) => {
  const { mode } = useTimesheetContext();
  const showName = mode === "AllTimesheets";

  const columnWidths = !showName
    ? "1fr 8fr 5fr repeat(10, 5fr) 5fr 2fr"
    : "1fr repeat(3, 5fr) 2fr repeat(10, 4fr) 5fr 2fr";
  const columnCount = !showName ? 15 : 17;

  const actions =
    mode === "AllTimesheets" ? (
      <div className="timesheet-review-buttons">
        <ApproveAll {...{ dispatch, state }} />
      </div>
    ) : undefined;

  const isMine = mode === "MyTimesheets";

  const allSelected = !isMine
    ? state.selectedItems.length === state.items.length &&
      state.items.length > 0
    : undefined;
  const onSelectAll = !isMine
    ? () =>
        dispatch({
          tag: allSelected ? "ClearSelected" : "SelectAll",
        })
    : undefined;

  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      <SearchListContent
        loading={state.tag === "Loading"}
        loadingMore={state.tag === "LoadingMore"}
        actions={actions}
      >
        <Card className="payroll-totals-card">
          <Table
            {...{
              columnWidths,
              columnCount,
              selectable: !isMine,
              onSelectAll,
              allSelected,
            }}
          >
            <TableRow header className="timesheet-row">
              <TableCell text="Week Start" />
              {showName && <TableCell text="Last Name" />}
              {showName && <TableCell text="First Name" />}
              <TableCell text="Pay" />
              <TableCell text="Work Loc" />
              <TableCell text="PT Hrs" />
              <TableCell text="Work Hrs" />
              <TableCell text="Time Off" />
              <TableCell text="Total Hrs" />
              <TableCell text="Mileage" />
              <TableCell text="Reimb" />
              <TableCell text="Payroll" />
              <TableCell text="Comp Paid" />
              <TableCell text="Total Expenses" />
              <TableCell text="Status" />
              <TableCell />
            </TableRow>
            {state.items &&
              state.items.map((x, i) => (
                <TimesheetRow
                  key={i}
                  {...{
                    row: x,
                    state,
                    dispatch,
                    search: state.filterOptions.searchText,
                    columnCount,
                  }}
                />
              ))}
          </Table>
          {state.showVisibility && (
            <VisibilityListener
              onShown={() => dispatch({ tag: "ScrolledToBottom" })}
            />
          )}
        </Card>
      </SearchListContent>
    </TextHighlighterProvider>
  );
};
