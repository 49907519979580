import React from "react";
import { CustomRollupInvoice, ProjectGroup } from "~/gql/types";
import TableCell from "~/table-cell";
import { InvoiceRow } from "./RollupInvoiceRow";
import styles from "./RollupInvoiceTable.module.scss";
import { currencyFormatter } from "~/currencyFormatter";
import Card from "@material/react-card";
import cn from "classnames";

type InvoiceTableProps = {
  rollupInvoice: CustomRollupInvoice;
  refetch: React.DispatchWithoutAction;
};

export const InvoiceTable: React.FC<InvoiceTableProps> = ({
  rollupInvoice,
  refetch,
}) => {
  const { lineItems, projectGroup, total } = rollupInvoice;

  const projects = projectGroup?.projects ?? [];

  const invoices = lineItems
    ? lineItems.map((item) => item?.invoice).filter((item) => item)
    : null;

  return (
    <Card className={styles["table-wrapper"]}>
      <div className={styles["line-item-table"]}>
        <div className={cn(styles["line-item-row"], styles["header"])}>
          <TableCell text="Project Id" />
          <TableCell text="Project Name" />
          <TableCell text="Invoice Number" />
          <TableCell text="Invoice Date" />
          <TableCell text="Invoice State" />
          <TableCell text="Total" className={styles["total-column"]} />
          <TableCell />
        </div>
        {projects.map((project, i) => {
          const invoice = invoices
            ? invoices.filter((i) => i!.project!.number == project!.number)
            : null;
          return (
            <InvoiceRow
              key={i}
              project={{
                ...project!,
                projectGroup: {
                  ...projectGroup,
                  name: rollupInvoice.projectGroupName,
                } as ProjectGroup,
              }}
              invoice={invoice ? invoice[0]! : null}
              customRollupInvoiceNumber={rollupInvoice.invoiceNumber}
              refetch={refetch}
              canManageCri={
                rollupInvoice.projectGroup?.customer?.canManageCri ?? false
              }
            />
          );
        })}
        <TotalRow {...{ total }} />
      </div>
    </Card>
  );
};

type TotalRowProps = {
  total: number;
};
const TotalRow: React.FC<TotalRowProps> = ({ total }) => {
  const rowClass = cn(styles["line-item-row"], styles["total-row"]);
  return (
    <div className={rowClass}>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell
        className={styles["total-column"]}
        text={currencyFormatter.format(total)}
      />
      <TableCell />
    </div>
  );
};
